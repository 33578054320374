import { useEffect } from 'react'
import { useStore } from './store'
import { changeMe, loadingMe, resetMe } from '../reducers/actions'
import logErr from '../lib/err'
import firebase from 'firebase/app'

const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export const useSubMe = () => {
  const [{ auth, me }, dispatch] = useStore()
  const isAuthed = auth && auth.user && auth.isAuthed

  useEffect(() => {
    if (isAuthed && auth.user.uid) {
      const db = firebase.firestore()

      const uid = auth.user.uid
      dispatch(loadingMe(true))

      const onUser = (userSnap) => {
        const data = (userSnap.exists && userSnap.data()) || null
        !supressLogging && console.log('me.user Update', uid, data)
        dispatch(changeMe(data))
        dispatch(loadingMe(false))
      }

      try {
        !supressLogging && console.log('SUB me.user')
        const meSub = db
          .collection('users')
          .doc(uid)
          .onSnapshot(onUser, (err) => {
            logErr(err, 'subscribe me.user failed')
            dispatch(resetMe())
            firebase.auth().signOut()
          })

        return () => {
          !supressLogging && console.log('UNSUB me.user')
          meSub()
        }
      } catch (err) {
        logErr(err, 'FAILED in Me sub')
        dispatch(resetMe())
      }
    } else {
      dispatch(resetMe())
    }
  }, [isAuthed])
  return me
}
export default useSubMe
